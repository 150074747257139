exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accommodation-apartments-js": () => import("./../../../src/pages/accommodation-apartments.js" /* webpackChunkName: "component---src-pages-accommodation-apartments-js" */),
  "component---src-pages-accommodation-facilities-js": () => import("./../../../src/pages/accommodation-facilities.js" /* webpackChunkName: "component---src-pages-accommodation-facilities-js" */),
  "component---src-pages-accommodation-faqs-js": () => import("./../../../src/pages/accommodation-faqs.js" /* webpackChunkName: "component---src-pages-accommodation-faqs-js" */),
  "component---src-pages-accommodation-index-js": () => import("./../../../src/pages/accommodation/index.js" /* webpackChunkName: "component---src-pages-accommodation-index-js" */),
  "component---src-pages-accommodation-journal-js": () => import("./../../../src/pages/accommodation-journal.js" /* webpackChunkName: "component---src-pages-accommodation-journal-js" */),
  "component---src-pages-accommodation-thank-you-js": () => import("./../../../src/pages/accommodation/thank-you.js" /* webpackChunkName: "component---src-pages-accommodation-thank-you-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-beach-hotel-js": () => import("./../../../src/pages/beach-hotel.js" /* webpackChunkName: "component---src-pages-beach-hotel-js" */),
  "component---src-pages-beach-house-js": () => import("./../../../src/pages/beach-house.js" /* webpackChunkName: "component---src-pages-beach-house-js" */),
  "component---src-pages-eat-drink-js": () => import("./../../../src/pages/eat-drink.js" /* webpackChunkName: "component---src-pages-eat-drink-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-links-js": () => import("./../../../src/pages/links.js" /* webpackChunkName: "component---src-pages-links-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-residences-js": () => import("./../../../src/pages/residences.js" /* webpackChunkName: "component---src-pages-residences-js" */),
  "component---src-pages-retail-js": () => import("./../../../src/pages/retail.js" /* webpackChunkName: "component---src-pages-retail-js" */),
  "component---src-templates-episode-js": () => import("./../../../src/templates/episode.js" /* webpackChunkName: "component---src-templates-episode-js" */),
  "component---src-templates-journal-js": () => import("./../../../src/templates/journal.js" /* webpackChunkName: "component---src-templates-journal-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

